#companion, #transcript {
  .sendbird-ui-header__middle__title {
    white-space: normal;
    max-width: 30rem !important;
  }

  @media (max-width: 1480px) {
    .sendbird-ui-header__middle__title {
      max-width: 22rem !important;
    }
  }
  @media (max-width: 1355px) {
    .sendbird-ui-header__middle__title {
      max-width: 18rem !important;
    }
  }

  @media (max-width: 768px) {
    .sendbird-ui-header__middle__title {
      max-width: 100% !important;
    }
  }

  @media (min-width: 1280px) {
    .sendbird-ui-header__left {
      margin-left: 110px;
    }
  }

  @media (max-width: 768px) {
    .sendbird-conversation__scroll-container {
      min-height: 100%;
      padding-bottom: 10rem;
    }
  }

  .sendbird-conversation__footer {
    border-top: none !important;
    z-index: 0 !important;
  }

  .sendbird-message-input-text-field {
    background-color: #F8F8F8;
    min-height: 128px !important;
    box-shadow: none !important;
    border: none !important;
    border-radius: 16px !important;
    padding-right: 50px !important;
  }

  .sendbird-message-input-wrapper__message-input {
    width: 100% !important;
  }

  .sendbird-iconbutton.sendbird-message-input--send {
    rotate: 320deg;
  }

  .sendbird-iconbutton.sendbird-message-input--send svg path {
    fill: #7D69CC !important;
  }

  .sendbird-iconbutton.sendbird-message-input--attach svg path {
    fill: #A29BBC !important;
  }

  .sendbird-iconbutton.sendbird-message-input--attach:hover svg path {
    fill: #7D69CC !important;
  }

  .sendbird-iconbutton.sendbird-message-input--attach,
  .sendbird-iconbutton.sendbird-message-input--send {
    position: absolute !important;
    bottom: 18px !important;
    right: 16px !important;
    left: unset !important;
    top: unset !important;
  }

  .sendbird-message-input-form__disabled {
    display: block !important;
    border-radius: 16px !important;
    opacity: 0.5;

    .sendbird-message-input--placeholder {
      display: none;
    }
  }

  .sendbird-message-input-wrapper {
    z-index: auto !important;
  }

  .sendbird-conversation__footer__typing-indicator__text {
    padding-left: 18px !important;
    padding-bottom: 85px !important;
    opacity: 0.6;
  }

  @media (max-width: 1024px) {
    .sendbird-conversation__footer__typing-indicator__text {
      padding-bottom: 105px !important;
    }
  }
  
  /* Hide message title/sender name */
  .sendbird-label.sendbird-user-message__sender-name,
  .sendbird-message-content__middle__sender-name {
    display: none !important;
  }
  
  /* Hide message contextual menu (three dots) */
  .sendbird-message-content__left,
  .sendbird-message-content__right
  {
    display: none !important;
  }
  
  /* Hide read indicators */
  .sendbird-message-status,
  .sendbird-read-receipt,
  .sendbird-message-status__text {
    display: none !important;
  }
}

#transcript {
  .sendbird-iconbutton {
    display: none !important;
  }
}

/* List */
.sendbird-channel-list__header {
  display: none;
}

/* Conversation Header */
.sendbird-ui-header.sendbird-chat-header.sendbird-conversation__channel-header {
  min-height: auto;
  height: auto;
  padding: 0 24px 16px 24px;
}

.sendbird-ui-header__middle__title {
  color: #1C1831 !important;
}

.sendbird-ui-header__right {
  display: none !important;
}

.sendbird-avatar-img.sendbird-image-renderer,
.sendbird-avatar-img.sendbird-image-renderer .sendbird-image-renderer__image,
.sendbird-chat-header--avatar--group-channel.sendbird-avatar {
  width: 48px !important;
  height: 48px !important;
  border: 1px solid #E8E8EA;
  cursor: default;
}

.sendbird-notification.sendbird-notification--frozen {
  display: none;
}

.sendbird-conversation {
  border: 0 !important;
  background-color: white !important;
}

.sendbird-theme--light .sendbird-channel-list {
  background-color: inherit !important;
}

.sendbird-theme--light .sendbird-icon-color--primary [class*="fill"] {
  fill: #1C1831 !important;
}

.sendbird-theme--light .sendbird-icon-color--primary [class*="stroke"] {
  stroke: #546bcf !important;
  stroke-width: 1px !important;
}

.sendbird-channel-list {
  width: 100% !important;
}

.sendbird-channel-list__body {
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
  scrollbar-width: none !important; /* Firefox */
}

.sendbird-channel-list__body::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.sendbird-user-message__text-balloon__inner__text-place__text {
  font-size: 16px !important;
}

.sendbird-user-message--outgoing .sendbird-user-message__text-balloon__inner__text-place__text {
  color: black !important;
}

.sendbird-label.sendbird-user-message__sender-name {
  display: none !important;
}

.sendbird-outgoing-og-message--left-padding {
  display: none !important;
}

.sendbird-separator .sendbird-separator__left,
.sendbird-separator .sendbird-separator__right {
  visibility: hidden;
}

/* Channel preview dropdown REMOVE */
.sendbird-channel-preview__action {
  display: none !important;
}

/* Emoji */
/*
.sendbird-user-message__more {
  display: none !important;
  opacity: 0 !important ;
}
*/
.sendbird-incoming-og-message__more {
  display: none !important;
}

/* Admin message */
.sendbird-admin-message .sendbird-admin-message__text {
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.4 !important;
  color: #3B3650 !important;
  white-space: pre-wrap;
  width: 412px;
  border-radius: 8px;
  text-align: center !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  background: #EDEFFF;
}

/*.sendbird-admin-message .sendbird-admin-message__text:before {*/
/*  background-image: url("/svg/lock_checked.svg");*/
/*  background-size: 24px 24px;*/
/*  min-width: 24px;*/
/*  width: 24px;*/
/*  height: 24px;*/
/*  content: "";*/
/*  margin-right: 8px;*/
/*}*/

.sendbird-separator__text * {
  padding: 8px !important;
  font-size: 14px !important;
  color: #6D697E !important;
  font-weight: normal !important;
  border: 1px solid #E8E8EA;
  border-radius: 16px !important;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
}

.sendbird-message-content__middle__sender-name {
  color: #6D697E !important;
}

/* Notification count */
.sendbird-badge {
  background-color: #f97c7c !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Rich content message */
.sendbird-text-message-item-body.outgoing {
  background: #F8F8F8 !important;
  color: #1C1831;
}

.sendbird-og-message-item-body.outgoing .sendbird-og-message-item-body__text-bubble {
  background: #7656C0 !important;
  color: white !important;
}

.sendbird-text-message-item-body.incoming {
  background-color: #E7E4F9 !important;
  color: #1C1831;
  border-radius: 16px !important;
}

.sendbird-text-message-item-body a,
.sendbird-og-message-item-body__text-bubble a {
  text-decoration: underline;
  color: #936cd2 !important;
}


.sendbird-message-content-reactions, .sendbird-message-content-reactions:hover {
  background-color: transparent !important;
}

.sendbird-message-status__text {
  color: #6D697E !important;
}

/* Hide timestamps by default and show on hover */
.sendbird-message-content__middle__body-container__created-at.right {
  right: -90px !important;
  color: #443f57 !important;
  display: none !important;
}

/* Show timestamps on hover */
.sendbird-message-content:hover .sendbird-message-content__middle__body-container__created-at.right {
  display: inline-block !important;
}

/* Footer & Input */
.sendbird-conversation__footer {
  border-top: 1px solid #E8E8EA !important;
  padding-top: 20px !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 1024px) {
  .sendbird-conversation__footer {
    padding-bottom: 20px !important;
    background: white;
    position: fixed !important;
    bottom: 0;
    left: 0;
    z-index: 10000 !important;
    opacity: 1;
  }
}

.sendbird-message-input-form__disabled {
  display: none;
}

.sendbird-message-input {
  display: flex !important;
  align-items: center !important;
}

.sendbird-message-input-wrapper__message-input {
  width: calc(100% - 80px) !important;
}

.sendbird-message-input-wrapper {
  z-index: 10000 !important;
}

.sendbird-message-input textarea {
  border: none !important;
  background-color: #F4F5FB !important;
  border-radius: 32px !important;
  color: #B8C1EA !important;
  font-size: 16px !important;
  padding-left: 25px !important;
  padding-top: 15px !important;
}

.sendbird-message-input--placeholder {
  color: #9A97A4 !important;
  font-size: 16px !important;
}

#sendbird-message-input-text-field {
  color: #1C1831 !important;
  border: 1px solid #D1D0D6 !important;
  border-radius: 8px;
  padding-right: 16px !important;
  font-size: 16px;
  height: auto !important;
}

textarea.sendbird-message-input--textarea.focus-visible {
  border: 1px solid #546bcf !important;
  box-shadow: none !important;
  background-color: white !important;
}

/* Message Input Icons */
.sendbird-iconbutton.sendbird-message-input--attach:hover,
.sendbird-iconbutton.sendbird-message-input--send:hover {
  background-color: transparent !important;
}

.sendbird-iconbutton.sendbird-message-input--attach,
.sendbird-iconbutton.sendbird-message-input--send {
  position: relative !important;
  left: 20px;
  top: 0;
}

.sendbird-iconbutton.sendbird-message-input--attach *,
.sendbird-iconbutton.sendbird-message-input--send * {
  fill: #1C1831 !important;
}

.sendbird-iconbutton.sendbird-message-input--attach:hover *,
.sendbird-iconbutton.sendbird-message-input--send:hover * {
  fill: #546BCF !important;
}
